import React, { Component, useEffect } from 'react'

import Alert from '@material-ui/lab/Alert'
import { LinearProgress } from '@material-ui/core'
import { UserRoles } from '@dramatikkens-hus-portal/shared'
import { gql } from 'graphql-request'
import { useGqlQuery } from 'hooks/useGqlQuery'
import { useQueryCache } from 'react-query'

type Props = {
  children: any
  roles: Array<UserRoles>
  showMessage?: boolean
  redirect?: string
}

const GET_USER = gql`
  query getUser {
    user {
      role
    }
  }
`

const WithRole = ({ children, roles, showMessage }: Props) => {
  const queryCache = useQueryCache()
  const { isLoading, isFetching, isError, isSuccess, isIdle, data, error, status } = useGqlQuery(
    ['user_role'],
    GET_USER
  )

  if (isLoading) {
    return <LinearProgress></LinearProgress>
  }
  if (isError) {
    return <div>Noe gikk galt, vennligst prøv igjen. </div>
  }
  //   console.log(error, data)

  if (roles && data && data.user && roles.indexOf(data.user.role) !== -1) {
    return <>{children}</>
  } else {
    if (error) {
      console.error(error)
    }

    if (showMessage) {
      return (
        <Alert color="error" severity="error">
          Du har ikke tilgang til denne siden
        </Alert>
      )
    }
    return <></>
  }
}

export default WithRole
